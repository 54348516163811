@use "../commons/variablesSCSS.scss";

.content:has(#accounts-container),
.content:has(#accounts) {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: flex-start;

  &:has(#account-rapid-access.visible) {
    gap: 10px;
    #menu-rapid-access {
      display: none;
    }
  }
  &:has(#newAccount) {
    #account-rapid-access {
      display: none;
    }
    #menu-rapid-access {
      display: none;
    }
  }
  &:has(#categories-mobile) {
    display: block;
  }
}

#account-rapid-access {
  position: sticky;
  top: 70px;
  left: 0;
  width: 300px;
  height: fit-content;
  .header {
    position: relative;
    > h3 {
      margin-top: 0;
    }
    #close {
      position: absolute;
      top: -3px;
      right: -3px;
      z-index: 2;
      border-radius: 15px !important;
    }
    input::placeholder {
      font-size: 0.85rem;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 75vh;
    overflow-y: scroll;
    margin: 0;
    width: 100%;

    .category-container {
      &:not(.actif) {
        .accounts-container {
          display: none;
        }
      }
      &.actif {
        .accounts-container {
          display: flex;
          flex-direction: column;
          gap: 5px;
          border-left: 2px solid var(--color-category);
          padding-left: 10px;
          margin-left: 12px;
          margin-bottom: 15px;
          padding-top: 15px;
          padding-bottom: 5px;
        }
      }
      .category {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: 5px;
        padding: 2px;
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--color-secondary);
        border-radius: 8px;
        margin-bottom: 0px;
        .shape {
          aspect-ratio: 1/1 !important;
          background-color: var(--color-category);
          svg path {
            color: var(--color-secondary);
          }
        }
      }
    }
    .account,
    .message {
      font-weight: 600;
      margin: 0;
      font-size: 0.8rem;
      opacity: 0.5;
      width: 100%;
      &:hover {
        opacity: 1;
      }

      &.actif {
        font-weight: 600;
        font-size: 0.8rem;
        color: var(--color-active);

        opacity: 1;
      }
    }
  }
  &.hidden {
    display: none;
  }
}

#menu-rapid-access {
  position: sticky;
  top: 80px;
  background-color: var(--color-gray0-transparent);
  backdrop-filter: blur(6px);
}

#accounts {
  width: 100%;
}

#accounts-container {
  position: relative;
  flex: 1;
  width: 100%;
  &:has(#menu-rapid-access) {
    grid-template-columns: auto 1fr;
  }

  .account-category-container {
    scroll-margin-top: 72px;
  }
  .category-actions {
    background-color: var(--color-glass);
    backdrop-filter: blur(6px);
    padding: 4px !important;
    border: 1px solid var(--color-gray2);
    position: sticky;
    top: 72px;
    z-index: 1;

    .category {
      margin: 0;
      padding: 7px;
      height: 100%;
      border-radius: calc(var(--size-borderRadius) * 4 / 5);
      background-color: var(--color-category);

      .shape {
        background-color: var(--color-category);
      }
      .title {
        font-size: 1.25rem;
        color: var(--color-secondary);
      }
    }
  }

  .tile {
    margin-top: 0;
    // padding: 12px 8px 20px;
  }
  &.tile,
  .tile.account {
    margin-top: 0;
    padding: 0;
    background-color: inherit;
  }
  .category {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 1.7rem;
    font-weight: 600;
    background-color: var(--color-secondary-background);
    padding: 18px 30px;
    margin-top: 50px;
    color: var(--color-secondary);
    margin-bottom: 12px;
    position: sticky;
    top: 0px;
    z-index: 1;
    border-radius: 10px;

    svg {
      color: var(--color-secondary);
    }
  }
  .account-container {
    scroll-margin-top: 150px;
    position: relative;
    background-color: var(--color-account);
    border-radius: 25px;
    padding: 7px;
    margin-bottom: 0px;

    > h3 {
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 18px;
      font-size: clamp(1rem, 3vw, 1.2rem);
      font-weight: 600;
      color: var(--color-link);
      opacity: 0.85;
    }

    &.bluePrint {
      background-color: var(--color-secondary-background2);
      .title h3,
      > h3 {
        color: var(--color-secondary);
      }
    }

    .input-container {
      max-width: unset;
    }

    input,
    textarea {
      font-weight: 500;
    }

    .no-input {
      height: 100%;
      display: flex;
      gap: 5px;
      justify-content: space-between;
      width: 100%;

      .single-button {
        padding: 8px;
      }
      .edit-btn {
        margin-left: 32px;
      }
    }

    button.dark {
      margin-top: 48px;
      margin-bottom: 12px;
    }

    .question-group-container {
      display: flex;
      flex-direction: column;
      background-color: var(--color-gray0-transparent);
      border-radius: var(--size-borderRadius);
      margin-bottom: 8px;
      padding: 10px;
      gap: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      hr.between-questions {
        margin: 15px 0 25px;
      }

      .exercice:not(.input-container) {
        margin-right: 0px;
        border: solid transparent 1px;
        padding: 11px 20px;
        border-radius: 50px;
        background-color: var(--color-gray0);
        &.clickable:hover:not(.message-box) {
          background-color: var(--color-gray1);
          border-radius: 10px;
          border-color: var(--color-gray2);
        }
      }
      .announcement {
        padding: 10px;
        background: var(--color-blue-light);
        color: var(--color-main);
        border-radius: 10px;
        margin: 5px;
      }

      h4 {
        font-size: clamp(0.95rem, 3vw, 1rem);
        line-height: 1.4rem;
        padding: 2px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        word-break: break-word;
      }

      .preview-container {
        position: relative;
        width: 100%;
        max-width: 175px;
        border-radius: 10px;

        aspect-ratio: 3/2;

        .modify-file {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .column {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.356);
            justify-content: center;
            align-items: center;
            cursor: pointer;
            svg {
              fill: var(--color-gray3);
              color: var(--color-gray3);
              stroke: var(--color-gray3);
            }
            &:hover {
              background: rgba(0, 0, 0, 0.656);
            }
          }
        }

        .preview {
          height: 100%;
          width: 100%;
          position: relative;

          .img-container {
            border-radius: 12px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      .title-container {
        display: grid;
        grid-template-columns: 1fr auto auto;
        row-gap: 16px;
        column-gap: 5px;
        margin: 6px 8px;
        align-items: center;
        min-height: fit-content;
        justify-content: space-between;

        .input {
          height: 100%;
          display: flex;
          gap: 5px;
          align-items: flex-start;
        }

        p {
          font-size: 0.85rem;
          font-style: italic;
          justify-self: flex-end;
        }
      }

      .previews {
        margin-inline: 10px;
        margin-top: 8px;
        gap: 5px;

        .preview {
          // max-width: 120px;
          width: 100%;
          aspect-ratio: 3/2;
          position: relative;
          text-align: center;

          .img-container {
            background-color: var(--color-gray1);
            border-radius: 10px;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      .simple-question {
        display: grid;
        gap: 20px;
        align-items: flex-start;
        word-break: break-word;

        .messages-list {
          border: 2px solid var(--color-gray1);
          border-radius: 17px;
          overflow-x: hidden;
          padding: 5px 13px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;

          max-height: 300px;
          overflow-y: auto;
          width: 100%;

          .date {
            display: flex;
            align-items: center;
            opacity: 0.5;
            margin-bottom: 2px;
            font-size: 0.85rem;
            svg {
              margin-left: 5px;
              margin-right: 5px;
            }
            &.denied {
              color: var(--color-error);
            }
          }

          .company-sent {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            .icon {
              background: var(--color-gray1);
              padding: 5px;
              border-radius: 50%;
              border: 2px solid var(--color-gray2);
            }
          }

          .message-container {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;

            .previews-container {
              margin-top: 15px;
              display: flex;
              gap: 5px;
              width: 100%;
              flex-wrap: wrap;
            }
            .preview,
            .preview-container {
              aspect-ratio: 3/2;
              max-width: 100%;
              width: 120px;
              border-radius: 8px;
              overflow: hidden;
              background-color: var(--color-gray0-transparent);
              color: var(--color-text-light);
              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            &.from-other {
              justify-content: flex-start;
              width: 100%;
              .date {
                margin-left: 20px;
              }

              .icon {
                top: -15px;
                left: -12px;
                position: absolute;
              }
              .message {
                align-items: flex-start;

                .previews-container {
                  justify-content: flex-start;
                }
                > .content {
                  border-radius: 5px 15px 15px 15px;
                  background: var(--color-gray1);
                  word-wrap: anywhere;
                }
              }
            }
            &.from-me {
              align-items: flex-end;
              width: 100%;

              .date {
                margin-right: 30px;
              }
              .icon {
                top: -15px;
                right: -15px;
                position: absolute;
              }

              .message {
                align-items: flex-end;
                margin-right: 10px;
                justify-content: flex-end;
                flex-direction: column;

                > .content {
                  border-radius: 15px 5px 15px 15px;
                  background: var(--color-main);
                  color: var(--color-gray0);
                  word-wrap: anywhere;
                }
                .previews-container {
                  justify-content: flex-end;
                }
              }
            }

            .message {
              max-width: 80%;
              display: flex;
              flex-direction: column;
              position: relative;

              > .content {
                width: fit-content;
                font-size: 0.9rem;
                font-weight: 500;
                padding: 12px 25px;
                word-break: break-word;
                pre {
                  white-space: pre-wrap;
                  // white-space: break-spaces;
                  line-height: 1.1rem;
                }
              }

              &.expert-deny {
                position: relative;
                > .content {
                  background: var(--color-errorBackground);
                  padding: 10px;
                  border-radius: 10px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                }

                .answer {
                  background: rgba(221, 74, 74, 0.12);
                  opacity: 0.7;
                  border-radius: 5px;
                  font-size: 0.9rem;
                  border: none;
                  border-left: 4px solid var(--color-error);
                  color: var(--color-error);
                  overflow: hidden;
                  pre {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    -webkit-line-clamp: 3;
                  }
                }
                .explanation {
                  font-size: 0.95rem;
                  padding-left: 8px;
                  color: var(--color-error);
                }
              }
            }
          }
        }

        .input-container {
          padding-top: 0;
          padding-bottom: 0;
          margin: 0;
          textarea,
          input {
            width: 100%;
            max-width: 100%;
          }
        }
        h5 {
          display: grid;
          gap: 15px;
          font-weight: 600;
          align-items: center;
          grid-template-columns: 25px auto;
          margin-bottom: 15px;
          svg {
            height: 25px;
            width: 25px;
          }
        }
        .buttons {
          display: flex;
          gap: 5px;
          justify-content: space-around;
        }
        .question {
          border-radius: 13px;
          padding: 5px 10px;
          font-style: normal;
          font-size: 0.95rem;
          font-weight: 500;
          height: fit-content;
        }
        &:has(.answer) {
          .question {
            background: var(--color-blue-light);
          }
        }

        .answer {
          border-radius: 13px;
          font-size: 0.95rem;
          .autocompletion-container {
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            margin-bottom: 5px;
            li {
              font-size: 0.85rem;
              font-weight: 600;
              cursor: pointer;
              padding: 7px 14px;
              background-color: var(--color-blue-light);
              color: var(--color-main);
              border-radius: 15px;
              position: relative;
              &:hover {
                background-color: var(--color-blue-background);
              }
              ul {
                position: absolute;
                &.active {
                  display: block;
                }
                &.inactive {
                  display: none;
                }
              }
            }
          }
          .input {
            display: grid;
            gap: 5px;
            align-items: flex-start;
          }

          .text {
            margin-bottom: 15px;
            font-weight: 500;
          }
          .skeleton-container {
            margin: 0;
          }
        }

        .modal {
          h2 {
            font-size: 2rem;
          }
          .messages-list {
            max-height: unset;
            overflow-y: unset;
            .icon {
              z-index: 12;
            }
          }
        }
      }
      .complement {
        margin: 0 5px;
        textarea {
          max-width: unset;
        }
      }
    }
  }
}

@media (max-width: variablesSCSS.$media-tablet) {
  #accounts-container {
    .account-container {
      .no-input {
        flex-direction: column;
        align-items: flex-start;
        p {
          margin: 0;
          align-self: flex-end;
          text-transform: capitalize;
        }
      }
      .question-group-container {
        .title-container {
          grid-template-columns: 1fr;
          > .exercice {
            grid-column: 1 / -1;
            grid-row: 2;
          }
        }
        .simple-question {
          grid-template-columns: 1fr !important;
          gap: 5px;
          .messages-list .message-container .previews-container {
            width: 100%;
            flex-wrap: wrap;
          }
          .buttons {
            justify-self: end;
          }
        }
      }
    }
    &.to-validate {
      .account-container {
        .no-input {
          flex-direction: row;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: variablesSCSS.$media-phone) {
  #accounts-container {
    .account-container {
      .question-group-container {
        .simple-question {
          .answer,
          .complement {
            display: flex;
            flex-flow: column;
            .previews {
              order: 1;
              // margin-top: 0;
              margin-bottom: 5px;
            }
            .autocompletion-container {
              order: 2;
            }
            div.row,
            .text-input {
              order: 3;
            }
          }
        }
      }
    }
  }
}
